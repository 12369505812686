import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../../components/sidebar";
import TopBar from "../../components/topbar";
import Select from 'react-select';


import DataTable from "react-data-table-component";
import { GlobalContext } from "../../contexts/global";

import { formatToPhone } from "brazilian-values"

import { Col, Row, Card, Form, ButtonGroup, Button } from 'react-bootstrap'

function TelaAlertas() {

    const { conexao, timeSelecionado, setLoading } = useContext(GlobalContext)

    const [alertas, setAlertas] = useState([])
    const [departamentos, setDepartamentos] = useState([])
    const [alertaSelecionado, setAlertaSelecionado] = useState({})

    const [dptosSelecionados, setDptosSelecionados] = useState([])

    const [telaCadastro, setTelaCadastro] = useState(false)

    const [editando, setEditando] = useState(false)

    useEffect(() => {
        GetAlertas()
        GetDepartamentos()
        setDptosSelecionados([])
    }, [timeSelecionado]) // eslint-disable-line react-hooks/exhaustive-deps

    function GetAlertas() {

        setLoading(true)
        setTelaCadastro(false)

        const url = conexao.endereco + '/alertas/listar'

        let body = {
            "time": localStorage.getItem('time_id')
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setAlertas(resposta)
                setLoading(false)
            }
        }
    }

    function DeleteAlerta(id) {
        setLoading(true)

        const url = conexao.endereco + '/alertas/remover'

        let body = {
            "id": id
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                setLoading(false)
                GetAlertas()
            }
        }
    }

    function CriarAlerta() {
        setLoading(true)

        const url = conexao.endereco + '/alertas/criar'

        let body = {
            "time": localStorage.getItem('time_id'),
            "descricao": alertaSelecionado.descricao,
            "texto": alertaSelecionado.texto,
            "departamento": alertaSelecionado.departamento,
            "dias": alertaSelecionado.dias_alerta,
            "horario": alertaSelecionado.horario_alerta,
            "email": alertaSelecionado.email,
            "telefone": alertaSelecionado.telefone,
            "status": alertaSelecionado.status,
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 201) {
                setAlertaSelecionado({})
                setTelaCadastro(false)
                GetAlertas()
                setLoading(false)
            }
        }
    }

    function AtualizarAlerta() {
        setLoading(true)

        const url = conexao.endereco + '/alertas/atualizar'

        let body = {
            "id": alertaSelecionado.id,
            "time": localStorage.getItem('time_id'),
            "descricao": alertaSelecionado.descricao,
            "texto": alertaSelecionado.texto,
            "departamento": alertaSelecionado.departamento,
            "dias": alertaSelecionado.dias_alerta,
            "horario": alertaSelecionado.horario_alerta,
            "email": alertaSelecionado.email,
            "telefone": alertaSelecionado.telefone,
            "status": alertaSelecionado.status,
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("PUT", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                setAlertaSelecionado({})
                setTelaCadastro(false)
                GetAlertas()
                setLoading(false)
            }
        }
    }

    function GetDepartamentos() {

        setLoading(true)

        const url = conexao.endereco + '/departamentos/listar'

        let body = {
            "time": timeSelecionado.id,
            "usuario": ""
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setDepartamentos(resposta)
                setLoading(false)
            }
        }
    }

    const paginationComponentOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const colunas = [
        {
            name: 'Código',
            selector: row => row.ale_id,
            sortable: true,
            maxWidth: '120px',
            omit: true
        },
        {
            name: 'Descrição',
            selector: row => row.ale_descricao,
            sortable: true,
        },
        {
            name: 'Departamento',
            selector: row => row.ale_departamento,
            sortable: true,
            minWidth: '180px',
        },
        {
            name: 'Dias',
            selector: row => row.ale_dias,
            sortable: true,
            maxWidth: '120px',
        },
        {
            name: 'Destinatário',
            selector: row => row.ale_email,
            sortable: true,
            minWidth: '180px',
        },
        {
            name: 'Status',
            selector: row => row.ale_status,
            sortable: true,
            maxWidth: '120px',
        },
        {
            name: 'Texto',
            selector: row => row.ale_texto,
            sortable: true,
            maxWidth: '120px',
            omit: 'true'
        },
    ];

    return (
        <>

            <div className='principal'>
                <Sidebar />
                <div className="principal-content">
                    <TopBar />
                    <div className="conteudo">
                        {!telaCadastro &&
                            <Row>
                                <Col>
                                    <ButtonGroup size="sm" style={{ marginBottom: 15 }}>
                                        <Button variant="success" style={{ minWidth: 120 }} onClick={() => {
                                            setEditando(false)
                                            setAlertaSelecionado({})
                                            setTelaCadastro(true)
                                        }}>Incluir</Button>
                                    </ButtonGroup>
                                    <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                                        <Card.Header className="dashboard-cartao">Cadastro de Alertas</Card.Header>
                                        <Card.Body>
                                            <DataTable
                                                columns={colunas}
                                                data={alertas}
                                                onRowClicked={(row, index) => {
                                                    setEditando(true)

                                                    const lista = row.ale_departamento.split(",")

                                                    

                                                    lista.forEach(element => {
                                                        for (let i = 0; i < departamentos.length; i++) {

                                                            if (departamentos[i].dpto_codigo == element) {
                                                                dptosSelecionados.push(departamentos[i])
                                                            }
                                                        }
                                                    })

                                                    setAlertaSelecionado({
                                                        "id": row.ale_id,
                                                        "time": row.ale_time,
                                                        "descricao": row.ale_descricao,
                                                        "texto": row.ale_texto,
                                                        "departamento": row.ale_departamento,
                                                        "dias_alerta": row.ale_dias,
                                                        "horario_alerta": row.ale_horario,
                                                        "email": row.ale_email,
                                                        "telefone": row.ale_telefone,
                                                        "status": row.ale_status,
                                                    })
                                                    setTelaCadastro(true)
                                                }}
                                                striped={true}
                                                highlightOnHover={true}
                                                responsive={true}
                                                pointerOnHover={true}
                                                noDataComponent={"Nenhum Registro Encontrado =/"}
                                                pagination={true}
                                                paginationComponentOptions={paginationComponentOptions}
                                            />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        }
                        {telaCadastro &&
                            <>
                                <Row>
                                    <Col>
                                        <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                                            <Card.Header className="dashboard-cartao">Dados do Alerta</Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    <Col lg={6}>
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                            <Form.Label>Descrição</Form.Label>
                                                            <Form.Control type="text" size="sm" onChange={(e) => { setAlertaSelecionado({ ...alertaSelecionado, descricao: e.target.value }) }} value={alertaSelecionado?.descricao} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={2}>
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                            <Form.Label>Dias Alerta</Form.Label>
                                                            <Form.Control size="sm" type="number" onChange={(e) => { setAlertaSelecionado({ ...alertaSelecionado, dias_alerta: e.target.value }) }} value={alertaSelecionado?.dias_alerta} />
                                                            {/* <Form.Text className="text-muted">
                                                                Qtde de dias antes do vencimento para gerar o alerta
                                                            </Form.Text> */}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={2}>
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                            <Form.Label>Horário Alerta</Form.Label>
                                                            <Form.Control size="sm" type="time" onChange={(e) => { setAlertaSelecionado({ ...alertaSelecionado, horario_alerta: e.target.value }) }} value={alertaSelecionado?.horario_alerta} />
                                                            {/* <Form.Text className="text-muted">
                                                                Horário que será gerado o alerta
                                                            </Form.Text> */}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={2}>
                                                        <Form.Group className="mb-3" controlId="status">
                                                            <Form.Label>Status</Form.Label>
                                                            <Form.Select aria-label="Default select example" size="sm" onChange={(e) => { setAlertaSelecionado({ ...alertaSelecionado, status: e.target.value }) }} value={alertaSelecionado?.status}>
                                                                <option>Selecione o Status</option>
                                                                <option value='ATIVO'>Ativo</option>
                                                                <option value='INATIVO'>Inativo</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={12}>
                                                        <Form.Group className="mb-3" controlId="status">
                                                            <Form.Label>Departamento</Form.Label>
                                                            <Select
                                                                closeMenuOnSelect={false}
                                                                noOptionsMessage={() => 'Nenhum departamento encontrado'}
                                                                onChange={(valor) => {
                                                                    const lista = valor.map((option) => option.value)
                                                                    setAlertaSelecionado({ ...alertaSelecionado, departamento: JSON.stringify(lista).replace('[', '').replace(']', '') })// setSelected(selectedOption);
                                                                }}
                                                                defaultValue={dptosSelecionados.map((item) => (
                                                                    { value: item.dpto_codigo, label: item.dpto_descricao }
                                                                ))}
                                                                placeholder="Selecione o Departamento"
                                                                isMulti
                                                                options={departamentos.map((item) => (
                                                                    { value: item.dpto_codigo, label: item.dpto_descricao }
                                                                ))}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={6}>
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                            <Form.Label>Endereço de E-mail</Form.Label>
                                                            <Form.Control size="sm" type="email" onChange={(e) => { setAlertaSelecionado({ ...alertaSelecionado, email: e.target.value }) }} value={alertaSelecionado?.email} />
                                                            <Form.Text className="text-muted">
                                                                Para envio de E-mail e Notificação Push
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                            <Form.Label>Telefone</Form.Label>
                                                            <Form.Control size="sm" type="text" onChange={(e) => { setAlertaSelecionado({ ...alertaSelecionado, telefone: e.target.value }) }} value={alertaSelecionado.telefone ? formatToPhone(alertaSelecionado?.telefone) : null} />
                                                            <Form.Text className="text-muted">
                                                                Para envio de WhatsApp
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                            <Form.Label>Mensagem a Ser Enviada</Form.Label>
                                                            <Form.Control size="sm" as="textarea" rows={10} onChange={(e) => { setAlertaSelecionado({ ...alertaSelecionado, texto: e.target.value }) }} value={alertaSelecionado?.texto} />
                                                            <Form.Text className="text-muted">
                                                                Junto a mensagem será enviado a relação dos itens
                                                            </Form.Text>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: 80 }}>
                                    <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <ButtonGroup size="sm" style={{ marginBottom: 15 }}>
                                            <Button variant="danger" style={{ minWidth: 120 }} onClick={() => {
                                                setDptosSelecionados([])
                                                setTelaCadastro(false)
                                            }}>Cancelar</Button>
                                            {editando &&
                                                <Button variant="warning" style={{ minWidth: 120 }} onClick={() => {
                                                    DeleteAlerta(alertaSelecionado.id)
                                                    setDptosSelecionados([])
                                                }}>Excluir</Button>
                                            }
                                            {editando &&
                                                <Button variant="success" style={{ minWidth: 120 }} onClick={() => {
                                                    // alert(JSON.stringify(alertaSelecionado))
                                                    AtualizarAlerta()
                                                    setDptosSelecionados([])
                                                }}>Atualizar</Button>
                                            }
                                            {!editando &&
                                                <Button variant="success" style={{ minWidth: 120 }} onClick={() => {
                                                    // alert(JSON.stringify(alertaSelecionado))
                                                    CriarAlerta()
                                                    setDptosSelecionados([])
                                                }}>Salvar</Button>
                                            }

                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </>
                        }
                    </div>
                </div>

            </div>
        </>
    )
}

export default TelaAlertas