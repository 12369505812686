import React, { useState, createContext } from 'react'

export const GlobalContext = createContext({})

function GlobalProvider({ children }) {

    const [conexao] = useState({
        endereco: process.env.REACT_APP_ENDERECO_CONEXAO
    })

    const [listaTimes, setListaTimes] = useState([])
    const [timeSelecionado, setTimeSelecionado] = useState({})
    const [periodo, setPeriodo] = useState()
    const [modalPeriodo, setModalPeriodo] = useState(false)
    const [loading, setLoading] = useState(false)

    return (
        <GlobalContext.Provider value={{
            conexao,
            listaTimes,
            setListaTimes,
            timeSelecionado,
            setTimeSelecionado,
            periodo,
            setPeriodo,
            modalPeriodo,
            setModalPeriodo,
            loading,
            setLoading
        }}>
            {children}
        </GlobalContext.Provider>
    )
}

export default GlobalProvider;