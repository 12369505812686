import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../../components/sidebar";
import TopBar from "../../components/topbar";

import DataTable from "react-data-table-component";
import { MdSettings } from 'react-icons/md'

import { GlobalContext } from "../../contexts/global";

import { Col, Row, Card, Modal, Form, Button } from 'react-bootstrap'

function AnalyticsProdutosPerdidos() {

    const { conexao, timeSelecionado, setLoading, periodo } = useContext(GlobalContext)

    const [coletas, setColetas] = useState([])

    const descricaoLote = JSON.parse(localStorage.getItem('time_config'))?.descricao_lote ? JSON.parse(localStorage.getItem('time_config'))?.descricao_lote : 'Lote'

    const descricaoLocal = JSON.parse(localStorage.getItem('time_config'))?.descricao_local ? JSON.parse(localStorage.getItem('time_config'))?.descricao_local : 'Local'

    useEffect(() => {


        if (periodo?.id === 'mes_atual') {
            var date = new Date();
            var primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
            var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            GetColetasPerdidas(primeiroDia, ultimoDia)

        } else

            if (periodo?.id === 'mes_anterior') {
                var date = new Date();
                var primeiroDia = new Date(date.getFullYear(), date.getMonth() - 1, 1);
                var ultimoDia = new Date(date.getFullYear(), date.getMonth() - 1 + 1, 0);

                GetColetasPerdidas(primeiroDia, ultimoDia)
            }


    }, [periodo])



    function GetColetasPerdidas(datainicial, datafinal) {

        setLoading(true)

        const url = conexao.endereco + '/coletas/listar/status'

        let body = {
            "time": localStorage.getItem('time_id'),
            "datainicial": datainicial.toISOString().split('T')[0],
            "datafinal": datafinal.toISOString().split('T')[0],
            "status": "Z"

        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setColetas(resposta)
                setLoading(false)
            }
        }
    }


    const paginationComponentOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const colunas = [
        {
            name: 'Código',
            selector: row => row.col_prod_codigo,
            sortable: true,
            maxWidth: '90px',
        },
        {
            name: 'Descrição',
            selector: row => row.prod_descricao,
            sortable: true,
            wrap: true,
            // maxWidth: '380px',
        },
        {
            name: 'Código de Barras',
            selector: row => row.prod_codbarras,
            sortable: true,
            maxWidth: '180px',
        },
        {
            name: descricaoLote,
            selector: row => row.col_lote,
            sortable: true,
            maxWidth: '180px',
        },
        {
            name: descricaoLocal,
            selector: row => row.col_local,
            sortable: true,
            wrap: true,
            maxWidth: '180px',
        },
        {
            name: 'Quantidade',
            selector: row => Number(row.quantidade_correta),
            sortable: true,
            maxWidth: '180px',
            right: 'true'
        },
        {
            name: 'Valor Unitário',
            selector: row => Number(row.col_venda),
            format: row => Number(row.col_venda).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            sortable: true,
            maxWidth: '180px',
            right: 'true'
        },
        {
            name: 'Valor Total',
            selector: row => Number(row.quantidade_correta * row.col_venda),
            format: row => Number(row.quantidade_correta * row.col_venda).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            sortable: true,
            maxWidth: '180px',
            right: 'true'
        },

    ];

    return (
        <>

            <div className='principal'>
                <Sidebar />
                <div className="principal-content">
                    <TopBar />
                    <div className="conteudo">
                        <Row>
                            <Col>
                                <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                                    <Card.Header className="dashboard-cartao" >Coletas Perdidas</Card.Header>
                                    <Card.Body>
                                        <DataTable
                                            columns={colunas}
                                            data={coletas}
                                            // onRowClicked={(row, index) => {
                                            //     GeraLogServidor(usuario.email, 'DASH', 'VENCIMENTOS', 'Clicou no Departamento: ' + row.departamento + ', Vencimento: ' + row.vencimento)
                                            //     CarregaDetalheColetas(row.departamento, row.vencimento)
                                            // }}
                                            striped={true}
                                            highlightOnHover={true}
                                            responsive={true}
                                            // pointerOnHover={true}
                                            noDataComponent={"Nenhum Registro Encontrado =/"}
                                            pagination={true}
                                            paginationComponentOptions={paginationComponentOptions}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>

            </div>


        </>
    )
}

export default AnalyticsProdutosPerdidos