import React, { useEffect, useState, useContext } from "react";
import Sidebar from "../../components/sidebar";
import TopBar from "../../components/topbar";

import Switch from "react-switch";
import Select from 'react-select'

import { Col, Row, Card, Modal, Form, Button, ButtonGroup } from 'react-bootstrap'
import DataTable from "react-data-table-component";
import { GlobalContext } from '../../contexts/global'

import { MdSettings } from 'react-icons/md'

function Integracoes() {

    const { conexao, timeSelecionado, loading, setLoading } = useContext(GlobalContext)

    const [integracoes, setIntegracoes] = useState([])
    const [integracaoSelecionada, setIntegracaoSelecionada] = useState({})

    const [dadosIntegracao, setDadosIntegracao] = useState({})

    const [modoEdicao, setModoEdicao] = useState(false)
    const [rpUtilizaTask, setRpUtilizaTask] = useState(false)

    const [modalCfgIntegracao, setModalCfgIntegracao] = useState(false)

    const erps_integrados = [
        { value: 'Alterdata Shop', label: 'Alterdata Shop' },
        { value: 'Casa Magalhaes', label: 'Casa Magalhães' }, 
        { value: 'Hiper', label: 'Hiper' },
        { value: 'MarketUp', label: 'MarketUp' },
        { value: 'RP Info', label: 'RP Info' },
        { value: 'Winthor Oracle', label: 'Winthor Oracle' },
    ]

    useEffect(() => {
        GetIntegracoes()
    }, [])

    function GetIntegracoes() {
        setLoading(true)

        const url = conexao.endereco + '/integracoes/listar'

        let body = {
            "usuario": localStorage.getItem('email')
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setIntegracoes(resposta)
                setLoading(false)
            }
        }
    }

    function AtualizarIntegracao(codigo, dados) {

        setLoading(true)

        const url = conexao.endereco + '/integracoes/atualizar'

        let body = {
            "codigo": codigo,
            "dados": dados
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("PUT", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {

            if (requisicao.status !== 200) {
                let resposta = JSON.parse(this.response)
                alert(resposta.message)
                setLoading(false)
            } else {
                GetIntegracoes()
            }
        }
    }

    function ExcluirIntegracao(codigo) {

        setLoading(true)

        setModalCfgIntegracao(false)

        const url = conexao.endereco + '/integracoes/excluir'

        let body = {
            "codigo": codigo
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("DELETE", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {

            if (requisicao.status !== 200) {
                let resposta = JSON.parse(this.response)
                alert(resposta.message)
                setLoading(false)
            } else {
                GetIntegracoes()
            }
        }
    }

    function CadastrarIntegracao(integracao, dados) {

        setLoading(true)

        const url = conexao.endereco + '/integracoes/criar'

        let body = {
            "descricao": integracao.descricao,
            "erp": integracao.sistema,
            "usuario": localStorage.getItem('email'),
            "dados": dadosIntegracao
        }

        console.log(JSON.stringify(body))

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {

            if (requisicao.status !== 201) {
                let resposta = JSON.parse(this.response)
                alert(resposta.message)
                setLoading(false)
            } else {
                GetIntegracoes()
            }
        }
    }

    const paginationComponentOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const colunas = [
        {
            name: 'Código',
            selector: row => row.inte_id,
            sortable: true,
            omit: true,
            // minWidth: '200px',
        },
        {
            name: 'Descrição',
            selector: row => row.inte_descricao,
            sortable: true,
            // minWidth: '200px',
        },
        {
            name: 'Sistema',
            selector: row => row.inte_erp,
            sortable: true,
            maxWidth: '120px'
        },
        {
            name: 'Status',
            selector: row => row.inte_status,
            sortable: true,
            maxWidth: '120px'
        },
        {
            name: 'Dados',
            selector: row => row.inte_dados,
            omit: true
        },
        {
            name: 'Configurar',
            button: true,
            center: true,
            cell: row => (
                <>

                    < a onClick={() => {
                        setModoEdicao(true)
                        setDadosIntegracao({})
                        setIntegracaoSelecionada({
                            ...integracaoSelecionada,
                            descricao: row.inte_descricao,
                            sistema: row.inte_erp,
                            status: row.inte_status,
                            usuario: localStorage.getItem('email'),
                            codigo: row.inte_id,
                        })
                        if (row.inte_dados) {
                            setDadosIntegracao(JSON.parse(row?.inte_dados))
                        }
                        setRpUtilizaTask(JSON.parse(row?.inte_dados).utilizatask)

                        setModalCfgIntegracao(true)
                    }} className="icone-datagrid">
                        <MdSettings size="24" title="Configurar Integração" />
                    </a>

                </>

            ),
        },
    ];

    return (
        <>

            <div className='principal'>
                <Sidebar />
                <div className="principal-content">
                    <TopBar />
                    <div className="conteudo">
                        <Row>
                            <Col>
                                <ButtonGroup size="sm" style={{ marginBottom: 15 }}>
                                    <Button variant="dark" onClick={() => {
                                        setModoEdicao(false)
                                        setDadosIntegracao({})
                                        setIntegracaoSelecionada({})
                                        setModalCfgIntegracao(true)
                                    }}>Nova Integração</Button>
                                </ButtonGroup>
                                <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                                    <Card.Header className="dashboard-cartao">Cadastro de Integrações</Card.Header>
                                    <Card.Body>
                                        <DataTable
                                            columns={colunas}
                                            data={integracoes}
                                            striped={true}
                                            highlightOnHover={true}
                                            responsive={true}
                                            noDataComponent={"Nenhum Registro Encontrado =/"}
                                            pagination={true}
                                            paginationComponentOptions={paginationComponentOptions}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>



            </div>

            <Modal
                show={modalCfgIntegracao}
                onHide={() => { setModalCfgIntegracao(false) }}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{integracaoSelecionada?.descricao}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!modoEdicao &&

                        <Row>
                            <Col>
                                <Select
                                    options={erps_integrados}
                                    isSearchable
                                    placeholder="Selecione a Integração"
                                    closeMenuOnSelect
                                    onChange={(e) => {
                                        // alert(JSON.stringify(e.value))
                                        setIntegracaoSelecionada({ ...integracaoSelecionada, sistema: e.value, descricao: e.label })
                                    }}
                                />
                            </Col>
                        </Row>
                    }

                    <Row>
                        {integracaoSelecionada.sistema === 'RP Info' &&
                            <Col lg={12}>

                                {!modoEdicao &&
                                    <br />
                                }
                                <span>Configurações do Banco de Dados</span>
                                <hr />

                                <Row>
                                    <Col lg={7}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Endereço IP</Form.Label>
                                            <Form.Control size="sm" type="text" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, endereco_erp: e.target.value }) }} value={dadosIntegracao.endereco_erp} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={5}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Porta</Form.Label>
                                            <Form.Control size="sm" type="number" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, porta_erp: e.target.value }) }} value={dadosIntegracao.porta_erp} />
                                        </Form.Group>
                                    </Col>
                                </Row>


                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Nome Base</Form.Label>
                                    <Form.Control size="sm" type="text" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, nome_banco_erp: e.target.value }) }} value={dadosIntegracao.nome_banco_erp} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Usuário Banco</Form.Label>
                                    <Form.Control size="sm" type="text" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, usuario_banco_erp: e.target.value }) }} value={dadosIntegracao.usuario_banco_erp} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Senha Banco</Form.Label>
                                    <Form.Control size="sm" type="password" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, senha_banco_erp: e.target.value }) }} value={dadosIntegracao.senha_banco_erp} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Tipo de Integração</Form.Label>
                                    <Form.Select aria-label="Tipo de Integração" size="sm" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, tipo_integracao: e.target.value }) }} value={dadosIntegracao.tipo_integracao}>
                                        <option>Selecione o Tipo</option>
                                        <option value='COMPLETA'>Coletas e Cadastros</option>
                                        <option value='CADASTROS'>Apenas Cadastros</option>
                                    </Form.Select>
                                </Form.Group>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ marginRight: 15 }}>Utiliza Task</span>
                                    <Switch
                                        checked={rpUtilizaTask}
                                        onChange={(e) => {
                                            setDadosIntegracao({ ...dadosIntegracao, utilizatask: e })
                                            setRpUtilizaTask(!rpUtilizaTask)
                                        }}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        className="react-switch"
                                        id="disabled-switch"
                                    />
                                </div>

                                {rpUtilizaTask &&

                                    <>

                                        <Row style={{ marginTop: 15 }}>
                                            <Col lg={7}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Label>Endereço IP</Form.Label>
                                                    <Form.Control size="sm" type="text" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, endereco_task: e.target.value }) }} value={dadosIntegracao.endereco_task} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={5}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Label>Porta</Form.Label>
                                                    <Form.Control size="sm" type="number" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, porta_task: e.target.value }) }} value={dadosIntegracao.porta_task} />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Nome Base</Form.Label>
                                            <Form.Control size="sm" type="text" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, nome_banco_task: e.target.value }) }} value={dadosIntegracao.nome_banco_task} />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Usuário Banco</Form.Label>
                                            <Form.Control size="sm" type="text" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, usuario_banco_task: e.target.value }) }} value={dadosIntegracao.usuario_banco_task} />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Senha Banco</Form.Label>
                                            <Form.Control size="sm" type="password" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, senha_banco_task: e.target.value }) }} value={dadosIntegracao.senha_banco_task} />
                                        </Form.Group>

                                    </>
                                }
                            </Col>
                        }
                        {integracaoSelecionada.sistema === 'Winthor Oracle' &&
                            <Col lg={12}>

                                {!modoEdicao &&
                                    <br />
                                }
                                <span>Configurações do Banco de Dados</span>
                                <hr />

                                <Row>
                                    <Col lg={7}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Endereço IP</Form.Label>
                                            <Form.Control size="sm" type="text" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, endereco_erp: e.target.value }) }} value={dadosIntegracao.endereco_erp} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={5}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Porta</Form.Label>
                                            <Form.Control size="sm" type="number" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, porta_erp: e.target.value }) }} value={dadosIntegracao.porta_erp} />
                                        </Form.Group>
                                    </Col>
                                </Row>


                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Nome Base</Form.Label>
                                    <Form.Control size="sm" type="text" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, nome_banco_erp: e.target.value }) }} value={dadosIntegracao.nome_banco_erp} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Usuário Banco</Form.Label>
                                    <Form.Control size="sm" type="text" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, usuario_banco_erp: e.target.value }) }} value={dadosIntegracao.usuario_banco_erp} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Senha Banco</Form.Label>
                                    <Form.Control size="sm" type="password" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, senha_banco_erp: e.target.value }) }} value={dadosIntegracao.senha_banco_erp} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Tipo de Integração</Form.Label>
                                    <Form.Select aria-label="Tipo de Integração" size="sm" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, tipo_integracao: e.target.value }) }} value={dadosIntegracao.tipo_integracao}>
                                        <option>Selecione o Tipo</option>
                                        <option value='COMPLETA'>Coletas e Cadastros</option>
                                        <option value='CADASTROS'>Apenas Cadastros</option>
                                    </Form.Select>
                                </Form.Group>

                            </Col>
                        }
                        {integracaoSelecionada.sistema === 'Alterdata Shop' &&
                            <Col lg={12}>

                                {!modoEdicao &&
                                    <br />
                                }
                                <span>Configurações do Banco de Dados</span>
                                <hr />

                                <Row>
                                    <Col lg={7}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Endereço IP</Form.Label>
                                            <Form.Control size="sm" type="text" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, endereco_erp: e.target.value }) }} value={dadosIntegracao.endereco_erp} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={5}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Porta</Form.Label>
                                            <Form.Control size="sm" type="number" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, porta_erp: e.target.value }) }} value={dadosIntegracao.porta_erp} />
                                        </Form.Group>
                                    </Col>
                                </Row>


                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Nome Base</Form.Label>
                                    <Form.Control size="sm" type="text" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, nome_banco_erp: e.target.value }) }} value={dadosIntegracao.nome_banco_erp} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Usuário Banco</Form.Label>
                                    <Form.Control size="sm" type="text" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, usuario_banco_erp: e.target.value }) }} value={dadosIntegracao.usuario_banco_erp} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Senha Banco</Form.Label>
                                    <Form.Control size="sm" type="password" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, senha_banco_erp: e.target.value }) }} value={dadosIntegracao.senha_banco_erp} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Tipo de Integração</Form.Label>
                                    <Form.Select aria-label="Tipo de Integração" size="sm" onChange={(e) => { setDadosIntegracao({ ...dadosIntegracao, tipo_integracao: e.target.value }) }} value={dadosIntegracao.tipo_integracao}>
                                        <option>Selecione o Tipo</option>
                                        <option value='CADASTROS'>Apenas Cadastros</option>
                                    </Form.Select>
                                </Form.Group>

                            </Col>
                        }
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => { setModalCfgIntegracao(false) }}>
                        Fechar
                    </Button>
                    {/* <Button variant="light" onClick={() => { alert(JSON.stringify(integracaoSelecionada)) }}>
                        Exibir JSON
                    </Button> */}
                    {modoEdicao &&
                        <Button variant="danger" onClick={() => { ExcluirIntegracao(integracaoSelecionada.codigo) }}>
                            Excluir
                        </Button>
                    }
                    {modoEdicao &&
                        <Button variant="dark" onClick={() => {

                            setModalCfgIntegracao(false)
                            AtualizarIntegracao(integracaoSelecionada.codigo, dadosIntegracao)
                            // alert(JSON.stringify(dadosIntegracao))

                        }}>Aplicar</Button>
                    }
                    {!modoEdicao &&
                        <Button variant="dark" onClick={() => {

                            setModalCfgIntegracao(false)
                            CadastrarIntegracao(integracaoSelecionada, dadosIntegracao)
                            // alert(JSON.stringify(dadosIntegracao))

                        }}>Cadastrar</Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Integracoes