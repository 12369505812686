import React, { useState, useEffect, useContext } from "react"
import { useNavigate, } from 'react-router-dom';
import { GlobalContext } from "../../contexts/global";
import './style.css';

import Barcode from '../Login/Barcode.gif'
import Logomarca from '../Login/logo_validades.fw.png'


function TelaLogin() {

    const [usuario, setUsuario] = useState('')
    const [senha, setSenha] = useState('')

    const [textoAcessar, setTextoAcessar] = useState('Acessar')

    const { conexao, timeSelecionado, periodo, modalPeriodo, setModalPeriodo, setPeriodo, loading, setLoading } = useContext(GlobalContext)

    const Navigate = useNavigate()

    useEffect(() => {
        if (loading) {
            setTextoAcessar('Aguarde...')
        } else {
            setTextoAcessar('Acessar')
        }
    }, [loading])

    useEffect(() => {
        localStorage.removeItem('Usuário Logado')
        localStorage.removeItem('Token')
        setUsuario(localStorage.getItem('email'))
    }, [])

    function Autenticar() {

        setLoading(true)

        const url = conexao.endereco + '/login'

        let body = {
            "usuario": usuario.toLowerCase(),
            "senha": senha
        }

        console.log('URL: ' + url)

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            setLoading(false)
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            setLoading(false)
            if (requisicao.status == 200) {
                let resposta = JSON.parse(this.response)
                localStorage.setItem('Usuário Logado', resposta.nome)
                localStorage.setItem('email', resposta.email)
                localStorage.setItem('Token', resposta.token)
                Navigate('/dashboard')
            } else {
                alert('Falha!')
            }
        }
    }

    return (
        <div className="main-login">

            <div className="left-login">
                <img
                    className="left-login-image"
                    src={Barcode}
                    alt={'Logo Gestor Atendimentos'}
                    loading={"lazy"}
                ></img>
            </div>
            <div className="right-login">
                <div className="card-login">
                    <div className="logo-login">
                        <img
                            className="logo-image"
                            src={Logomarca}
                            alt={'Logo Gestor Atendimentos'}
                            loading={"lazy"}
                        ></img>
                    </div>
                    <div className="textfield">
                        <label for="usuario">Usuário</label>
                        <input type={"text"} value={usuario} onChange={(e) => { setUsuario(e.target.value) }}></input>
                    </div>
                    <div className="textfield">
                        <label for="senha">Senha</label>
                        <input type={"password"} value={senha} onChange={(e) => { setSenha(e.target.value) }}></input>
                    </div>
                    <button className="btn-login" onClick={() => {
                        Autenticar()
                    }}>{textoAcessar}</button>
                </div>
            </div>

        </div>
    )
}

export default TelaLogin