import React, { useEffect, useState, useContext } from "react";
import Sidebar from "../../components/sidebar";
import TopBar from "../../components/topbar";

import { Col, Row, Card, Modal, Table, Button, Tabs, Tab, ButtonGroup } from 'react-bootstrap'
import DataTable from "react-data-table-component";
import { GlobalContext } from '../../contexts/global'

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

function Coletas() {

    const { conexao, timeSelecionado, loading, setLoading } = useContext(GlobalContext)

    const [indiceColetas, setIndiceColetas] = useState([])
    const [coletas, setColetas] = useState([])
    const [historicoColeta, setHistoricoColeta] = useState([])

    const [indiceSelecionado, setIndiceSelecionado] = useState({})
    const [coletaSelecionada, setColetaSelecionada] = useState({})

    const [telaDetalhe, setTelaDetalhe] = useState(false)
    const [modalDetColeta, setModalDetColeta] = useState(false)

    const [tabSelecionada, setTabSelecionada] = useState(false)

    const descricaoLote = JSON.parse(localStorage.getItem('time_config'))?.descricao_lote ? JSON.parse(localStorage.getItem('time_config'))?.descricao_lote : 'Lote'

    const descricaoLocal = JSON.parse(localStorage.getItem('time_config'))?.descricao_local ? JSON.parse(localStorage.getItem('time_config'))?.descricao_local : 'Local'

    // useEffect(() => {
    //     GetIndiceColetas()
    // }, [])

    useEffect(() => {
        GetIndiceColetas(0, 10)
    }, [timeSelecionado.id])

    function GetIndiceColetas(offset, limit) {

        setLoading(true)

        const url = conexao.endereco + '/coletas/indice'

        let body = {
            "usuario": "",
            "time": localStorage.getItem('time_id'),
            "vencidos": "N",
            "offset": offset,
            "limit": limit
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 100000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setIndiceColetas(resposta)
                setLoading(false)
            }
        }
    }

    function GetColetas(departamento, vencimento) {

        setLoading(true)

        const url = conexao.endereco + '/coletas/listar'

        let body = {
            "usuario": "",
            "time": localStorage.getItem('time_id'),
            "departamento": departamento,
            "data": vencimento
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setColetas(resposta)
                setLoading(false)
            }
        }
    }

    function GetDetalheColeta(identificacao) {

        setLoading(true)

        const url = conexao.endereco + '/coletas/detalhe'

        let body = {
            "usuario": "",
            "time": localStorage.getItem('time_id'),
            "id": identificacao
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setColetaSelecionada(resposta)
                setLoading(false)
                setModalDetColeta(true)
                if (resposta.qtde_historico > 0) {
                    GetHistColeta(identificacao)
                } else {
                    setHistoricoColeta([])
                }
            }
        }
    }

    function GetHistColeta(identificacao) {

        setLoading(true)

        const url = conexao.endereco + '/coletas/historico'

        let body = {
            "id": identificacao
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setHistoricoColeta(resposta)
                setLoading(false)
            }
        }
    }

    const paginationComponentOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const colunasIndice = [
        {
            name: 'Departamento',
            selector: row => row.departamento,
            cellExport: row => row.departamento,
            sortable: true,
            // minWidth: '200px',
        },
        {
            name: 'Vencimento',
            selector: row => row.vencimento,
            cellExport: row => row.vencimento,
            sortable: true,
            // minWidth: '200px',
        },
        {
            name: 'Dias Até o Vencimento',
            selector: row => Number(row.dias),
            cellExport: row => Number(row.dias),
            sortable: true,
            maxWidth: '180px',
            right: 'true'
        },
        {
            name: 'Produtos',
            selector: row => Number(row.produtos),
            cellExport: row => Number(row.produtos),
            sortable: true,
            maxWidth: '120px',
            right: 'true'
        },
        {
            name: 'Volumes',
            selector: row => Number(row.quantidades),
            cellExport: row => Number(row.quantidades),
            sortable: true,
            maxWidth: '120px',
            right: 'true'
        },
        {
            name: 'Valor',
            selector: row => Number(row.total),
            cellExport: row => Number(row.total),
            format: row => Number(row.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            sortable: true,
            maxWidth: '120px',
            right: 'true'
        },
    ];

    const colunasColetas = [
        {
            name: 'Código',
            selector: row => row.col_prod_codigo,
            cellExport: row => row.col_prod_codigo,
            sortable: true,
            maxWidth: '90px',
        },
        {
            name: 'Descrição',
            selector: row => row.prod_descricao,
            cellExport: row => row.prod_descricao,
            sortable: true,
            wrap: true,
            // maxWidth: '380px',
        },
        {
            name: 'Código de Barras',
            selector: row => row.prod_codbarras,
            cellExport: row => row.prod_codbarras,
            sortable: true,
            maxWidth: '180px',
        },
        {
            name: descricaoLote,
            selector: row => row.col_lote,
            cellExport: row => row.col_lote,
            sortable: true,
            maxWidth: '180px',
        },
        {
            name: descricaoLocal,
            selector: row => row.col_local,
            cellExport: row => row.col_local,
            sortable: true,
            wrap: true,
            maxWidth: '180px',
        },
        {
            name: 'Quantidade',
            selector: row => Number(row.col_qtde),
            cellExport: row => Number(row.col_qtde),
            sortable: true,
            maxWidth: '180px',
            right: 'true'
        },
        {
            name: 'Valor Unitário',
            selector: row => Number(row.col_venda),
            cellExport: row => Number(row.col_venda),
            format: row => Number(row.col_venda).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            sortable: true,
            maxWidth: '180px',
            right: 'true'
        },
        {
            name: 'Valor Total',
            selector: row => Number(row.total),
            cellExport: row => Number(row.total),
            format: row => Number(row.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            sortable: true,
            maxWidth: '180px',
            right: 'true'
        },
    ];

    const colunasDetalhe = [
        {
            name: 'Campo',
            selector: row => row.Campo,
            maxWidth: '200px',
        },
        {
            name: 'Conteúdo',
            selector: row => row.Conteudo,
        }
    ];

    const colunasHistorico = [
        {
            name: 'Data',
            selector: row => row.data_format,
            cellExport: row => row.data_format,
            maxWidth: '120px'
        },
        {
            name: 'Usuário',
            selector: row => row.hcol_usuario,
            cellExport: row => row.hcol_usuario,
            maxWidth: '200px',
        },
        {
            name: 'Qtde Anterior',
            selector: row => Number(row.hcol_qtde_anterior),
            cellExport: row => Number(row.hcol_qtde_anterior),
            right: 'true'
        },
        {
            name: 'Qtde Atualizada',
            selector: row => Number(row.hcol_qtde),
            cellExport: row => Number(row.hcol_qtde),
            right: 'true'
        }
    ];

    const listaDetalhesColeta = [
        {
            "Campo": "Produto",
            "Conteudo": coletaSelecionada?.prod_descricao
        },
        {
            "Campo": "Código de Barras",
            "Conteudo": coletaSelecionada?.prod_codbarras
        },
        {
            "Campo": "Usuário Coleta",
            "Conteudo": coletaSelecionada?.col_user_integracao ? coletaSelecionada?.col_user_integracao : coletaSelecionada?.prod_usuario
        },
        {
            "Campo": "Data da Coleta",
            "Conteudo": coletaSelecionada?.data_format
        },
        {
            "Campo": "Data de Vencimento",
            "Conteudo": coletaSelecionada?.vencimento_format
        },
        {
            "Campo": "Quantidade Original",
            "Conteudo": coletaSelecionada?.qtde_original
        },
        {
            "Campo": "Quantidade Atual",
            "Conteudo": coletaSelecionada?.col_qtde
        },
        {
            "Campo": "Data Última Atualizacao",
            "Conteudo": coletaSelecionada?.ultima_atualizacao
        },
        {
            "Campo": descricaoLote,
            "Conteudo": coletaSelecionada?.col_lote
        },
        {
            "Campo": descricaoLocal,
            "Conteudo": coletaSelecionada?.col_local
        },
        {
            "Campo": "Time",
            "Conteudo": coletaSelecionada?.time
        },
    ]

    const tableDataConfigIndice = {
        columns: colunasIndice,
        data: indiceColetas,
        filterPlaceholder: 'Filtrar',
        exportHeaders: true,
        print: true,
        export: true
    };
    const tableDataConfigColetas = {
        columns: colunasColetas,
        data: coletas,
        filterPlaceholder: 'Filtrar',
        exportHeaders: true,
        print: true,
        export: true
    };


    return (
        <>

            <div className='principal'>
                <Sidebar />
                <div className="principal-content">
                    <TopBar />
                    <div className="conteudo">
                        <Row>
                            {!telaDetalhe &&
                                <Col>
                                    <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                                        <Card.Header className="dashboard-cartao">Índice de Coletas</Card.Header>
                                        <Card.Body>
                                            <DataTableExtensions {...tableDataConfigIndice}>
                                                <DataTable
                                                    columns={colunasIndice}
                                                    data={indiceColetas}
                                                    onChangePage={(page) => {
                                                        GetIndiceColetas(10 * page, 10)
                                                    }}
                                                    onChangeRowsPerPage={(rows) => {
                                                        alert(rows)
                                                    }}
                                                    // paginationTotalRows={}
                                                    paginationServer
                                                    striped={true}
                                                    paginationPerPage={10}
                                                    highlightOnHover={true}
                                                    pointerOnHover={true}
                                                    onRowClicked={(row, index) => {

                                                        GetColetas(row.departamento, row.vencimento)
                                                        setIndiceSelecionado({
                                                            row
                                                        })
                                                        setTelaDetalhe(true)
                                                    }}
                                                    responsive={true}
                                                    noDataComponent={"Nenhum Registro Encontrado =/"}
                                                    pagination={true}
                                                    paginationComponentOptions={paginationComponentOptions}
                                                />
                                            </DataTableExtensions>

                                        </Card.Body>
                                    </Card>
                                </Col>
                            }
                            {telaDetalhe &&
                                <Col>
                                    <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                                        <Card.Header className="dashboard-cartao-det-coleta">
                                            <div style={{ alignItems: 'center', display: 'flex' }}>
                                                Produtos do Departamento &nbsp;<strong>{indiceSelecionado?.row?.departamento}</strong>&nbsp; com Vencimento em &nbsp;<strong>{indiceSelecionado?.row.vencimento}</strong>
                                            </div>
                                            <div>
                                                <Button variant="light" size="sm" onClick={() => {
                                                    setColetas([])
                                                    setTelaDetalhe(false)

                                                }}>Voltar</Button>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <DataTableExtensions {...tableDataConfigColetas}>
                                                <DataTable
                                                    columns={colunasColetas}
                                                    data={coletas}
                                                    striped={true}
                                                    highlightOnHover={true}
                                                    pointerOnHover={true}
                                                    onRowClicked={(row, index) => {
                                                        setTabSelecionada('detalhes')
                                                        GetDetalheColeta(row?.col_id)
                                                    }}
                                                    responsive={true}
                                                    noDataComponent={"Nenhum Registro Encontrado =/"}
                                                    pagination={true}
                                                    paginationComponentOptions={paginationComponentOptions}
                                                />
                                            </DataTableExtensions>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            }
                        </Row>
                    </div>
                </div>



            </div>

            <Modal
                show={modalDetColeta}
                onHide={() => { setModalDetColeta(false) }}
                backdrop="static"
                keyboard={false}
                size="sm"
                centered
                dialogClassName="modal-60"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{coletaSelecionada?.prod_descricao}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                            <ButtonGroup size="sm" style={{ marginBottom: 15 }}>
                                <Button variant="light" active={tabSelecionada === 'detalhes' ? true : false} onClick={() => {
                                    setTabSelecionada('detalhes')
                                }}>Dados da Coleta</Button>
                                <Button variant="light" active={tabSelecionada === 'historico' ? true : false} onClick={() => {
                                    setTabSelecionada('historico')
                                }}>Histórico de Atualizações</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img
                                width={'auto'}
                                height={300}
                                src={'https://img.simplustec.com.br/product_images/' + parseInt(coletaSelecionada?.prod_codbarras, 10) + '/1/' + parseInt(coletaSelecionada?.prod_codbarras, 10) + '_1_1_600_72_RGB.png?pid=14136&cmid=3744&action=API&hash=e17c600fb462331d05b82f81b2f19e1e'}
                                loading="lazy"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "https://app.gestaodevalidades.com.br/images/not_found.png";
                                }}
                            />
                        </Col>
                        <Col lg={8} >
                            {tabSelecionada === 'detalhes' &&
                                <DataTable
                                    columns={colunasDetalhe}
                                    data={listaDetalhesColeta}
                                    striped={true}
                                    dense
                                    highlightOnHover={true}
                                    responsive={true}
                                    noDataComponent={"Nenhum Registro Encontrado =/"}
                                />
                            }
                            {tabSelecionada === 'historico' &&
                                <DataTable
                                    columns={colunasHistorico}
                                    data={historicoColeta}
                                    striped={true}
                                    highlightOnHover={true}
                                    pointerOnHover={true}
                                    responsive={true}
                                    noDataComponent={"Nenhum Registro Encontrado =/"}
                                    pagination={true}
                                    paginationComponentOptions={paginationComponentOptions}
                                />
                            }
                        </Col>
                    </Row>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => { setModalDetColeta(false) }}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default Coletas