import React, { useContext, useEffect } from "react";
import { Button, Dropdown, Row, Col, Modal } from 'react-bootstrap'

import { GlobalContext } from "../contexts/global";

import { MdCalendarToday, MdMenu } from 'react-icons/md'

import { useNavigate } from 'react-router-dom'

function TopBar() {

    const Navigate = useNavigate()

    const { conexao, listaTimes, setListaTimes, timeSelecionado, setTimeSelecionado, periodo, setPeriodo, setModalPeriodo, loading, setLoading } = useContext(GlobalContext)

    useEffect(() => {
        setPeriodo({
            ...periodo,
            nome: 'Mês Atual',
            id: 'mes_atual'
        })
        GetTimes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function GetTimes() {

        // setLoading(true)

        const url = conexao.endereco + '/times/listar'

        let body = {
            "usuario": localStorage.getItem('email')
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            // setLoading(false)
            console.log('Deu TimeOut')
            // setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            // setLoading(false)
        }

        requisicao.onload = function () {
            // setLoading(false)
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)

                setListaTimes(resposta)

                const timeLocal = localStorage.getItem('time_id')

                if (timeLocal) {
                    setTimeSelecionado({
                        ...timeSelecionado,
                        id: localStorage.getItem('time_id'),
                        nome: localStorage.getItem('time_nome')
                    })
                } else {
                    setTimeSelecionado({
                        ...timeSelecionado,
                        nome: resposta[0].time_nome,
                        id: resposta[0].time_id
                    })
                    localStorage.setItem('time_nome', resposta[0].time_nome)
                    localStorage.setItem('time_id', resposta[0].time_id)
                    localStorage.setItem('time_config', resposta[0].time_configuracoes)
                }



            } else {
                if (requisicao.status === 204) {
                    alert("Login permitido apenas para usuários com times")
                    Navigate('/')
                }
            }
        }
    }

    return (
        <div className='topbar'>
            <div style={{ paddingLeft: 15 }}>
                <div style={{ display: 'flex', width: '100vw', alignItems: 'center' }}>
                    <div style={{ width: '50vw' }}>
                        Olá, {localStorage.getItem('Usuário Logado')}
                    </div>
                    <div style={{ width: '30vw', display: 'flex', justifyContent: 'flex-end' }}>

                        <div className="seletor-periodo-mobile" style={{ marginRight: 15 }}>
                            <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    <MdCalendarToday />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => {

                                    }}>XXX</Dropdown.Item>

                                </Dropdown.Menu>

                            </Dropdown>
                        </div>

                        <div className="seletor-periodo-desktop" style={{ marginRight: 15 }}>
                            <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    {periodo?.nome}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => { setPeriodo({ ...periodo, nome: 'Mês Atual', id: 'mes_atual' }) }}>Mês Atual</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { setPeriodo({ ...periodo, nome: 'Mês Anterior', id: 'mes_anterior' }) }}>Mês Anterior</Dropdown.Item>
                                    <Dropdown.Item onClick={() => { setModalPeriodo(true) }}>Informar Período</Dropdown.Item>

                                </Dropdown.Menu>

                            </Dropdown>
                        </div>


                        <Dropdown className="seletor-time-desktop">
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                {timeSelecionado.nome}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {listaTimes.map((item) => {
                                    return (
                                        <Dropdown.Item onClick={() => {
                                            setTimeSelecionado({
                                                ...timeSelecionado,
                                                nome: item.time_nome,
                                                id: item.time_id
                                            })
                                            localStorage.setItem('time_nome', item.time_nome)
                                            localStorage.setItem('time_id', item.time_id)
                                            localStorage.setItem('time_config', item.time_configuracoes)
                                        }}>{item.time_nome}</Dropdown.Item>
                                    )
                                })}
                            </Dropdown.Menu>

                        </Dropdown>
                        <Dropdown className="seletor-time-mobile">
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                <MdMenu />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {listaTimes.map((item) => {
                                    return (
                                        <Dropdown.Item onClick={() => {
                                            setTimeSelecionado({
                                                ...timeSelecionado,
                                                nome: item.time_nome,
                                                id: item.time_id
                                            })
                                        }}>{item.time_nome}</Dropdown.Item>
                                    )
                                })}
                            </Dropdown.Menu>

                        </Dropdown>
                        <Button
                            variant="light"
                            style={{ marginLeft: 15 }}
                            onClick={() => {
                                Navigate('/')
                            }}
                        >Sair
                        </Button>
                    </div>
                </div>
            </div>

            <Modal
                show={loading}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body>
                    <Row>
                        <Col style={{ textAlign: 'center' }}>
                            Processando
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default TopBar