import { Card, Row, Col, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Chart } from 'react-google-charts';

export function Cartao(props) {
    return (
        <div className="cartao">
            <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                <Card.Header className="dashboard-cartao">{props.titulo}</Card.Header>
                <Card.Body>
                    <Card.Text>
                        {props.conteudo}
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}

export function CartaoBotao(props) {

    const Navigate = useNavigate();
    
    return (
        <div className="cartao">
            <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                <Card.Header className="dashboard-cartao">{props.titulo}</Card.Header>
                <Card.Body>
                    <Card.Text>
                        {props.conteudo}
                    </Card.Text>
                </Card.Body>
                <Card.Footer>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant='secondary' size='sm' onClick={() => Navigate(props.link)}>{props.textobotao}</Button>
                    </div>
                </Card.Footer>
            </Card>
        </div>
    )
}

export function CartaoNumeros(props) {

    const Navigate = useNavigate();


    return (
        <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
            <Card.Header className="dashboard-cartao" onClick={() => {
                if (props.link) {
                    Navigate(props.link)
                }
            }}>{props.titulo}</Card.Header>
            <Card.Body>
                <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                    <Col style={{ width: '50%', borderRight: '1px solid gray' }}>
                        <Card.Text style={{ textAlign: 'center' }}>
                            <strong style={{ fontSize: 28, color: props.cor }}>{props.numero}</strong>
                            <p style={{ color: 'grey' }}>Volume(s)</p>
                        </Card.Text>
                    </Col>
                    <Col style={{ width: '50%' }}>
                        <Card.Text style={{ textAlign: 'right' }}>
                            <strong style={{ fontSize: 18, color: props.cor }}>{props.valor}</strong>
                            <p style={{ color: 'grey', fontSize: 14 }}>Valor R$</p>
                        </Card.Text>
                        <Card.Text style={{ textAlign: 'right' }}>
                            <strong style={{ fontSize: 18, color: props.cor }}>{props.participacao}</strong>
                            <p style={{ color: 'grey', fontSize: 14 }}>Participação R$</p>
                        </Card.Text>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export function CartaoProximosVencimentos(props) {
    return (
        <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
            <Card.Header className="dashboard-cartao">{props.titulo}</Card.Header>
            <Card.Body>
                <Chart
                    chartType="BarChart"
                    width="100%"
                    height="130px"
                    data={props.data}
                    options={props.options}
                />
            </Card.Body>
        </Card>
    )
}