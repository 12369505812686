import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import GlobalProvider from './contexts/global'

import Dashboard from './pages/Dashboard';
import TelaLogin from './pages/Login';
import CadastroProdutos from './pages/Cadastros/produtos'
import CadastroDepartamentos from './pages/Cadastros/departamentos'
import Coletas from './pages/Coletas';
import Analytics from './pages/Analytics';
import Integracoes from './pages/Integracoes'
import Configuracoes from './pages/Configuracoes'
import Ajuda from './pages/Ajuda'
import TelaAlertas from './pages/Alertas'
import TelaMembros from './pages/Membros'
import AnalyticsProdutosPerdidos from './pages/Analytics/produtos-perdidas';
import AnalyticsAnaliseVencimentos from './pages/Analytics/analise-vencimentos-dpto-prod';




function App() {
  return (

    <GlobalProvider>

      <BrowserRouter>

        <Routes>
          <Route exact path="/" element={<TelaLogin />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/cadastro-produtos" element={<CadastroProdutos />} />
          <Route exact path="/cadastro-departamentos" element={<CadastroDepartamentos />} />
          <Route exact path="/analytics" element={<Analytics />} />
          <Route exact path="/analytics/produtos/perdidos" element={<AnalyticsProdutosPerdidos />} />
          <Route exact path="/analytics/vencimentos/analise/:tipo" element={<AnalyticsAnaliseVencimentos />} />
          <Route exact path="/coletas" element={<Coletas />} />
          <Route exact path="/integracoes" element={<Integracoes />} />
          <Route exact path="/alertas" element={<TelaAlertas />} />
          <Route exact path="/membros" element={<TelaMembros />} />
          <Route exact path="/configuracoes" element={<Configuracoes />} />
          <Route exact path="/ajuda" element={<Ajuda />} />
        </Routes>

      </BrowserRouter>

    </GlobalProvider>
  );
}

export default App;
