import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../../components/sidebar";
import TopBar from "../../components/topbar";

import DataTable from "react-data-table-component";
import { MdSettings } from 'react-icons/md'
import { useParams } from 'react-router-dom';
import { GlobalContext } from "../../contexts/global";
import moment from "moment";
import { Col, Row, Card, Modal, Form, Button, ButtonGroup } from 'react-bootstrap'

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

function AnalyticsAnaliseVencimentos() {

    const { conexao, timeSelecionado, setLoading, periodo } = useContext(GlobalContext)

    const [coletas, setColetas] = useState([])
    const [coletasProdutos, setColetasProdutos] = useState([])

    const [vencimentos, setVencimentos] = useState(0)
    const [verificacao, setVerificacao] = useState(0)
    const [modalDetColeta, setModalDetColeta] = useState(false)

    const [coletaSelecionada, setColetaSelecionada] = useState({})
    const [historicoColeta, setHistoricoColeta] = useState([])

    const [tabSelecionada, setTabSelecionada] = useState(false)

    const [tab, setTab] = useState('principal')

    const { tipo } = useParams()


    const descricaoLote = JSON.parse(localStorage.getItem('time_config'))?.descricao_lote ? JSON.parse(localStorage.getItem('time_config'))?.descricao_lote : 'Lote'

    const descricaoLocal = JSON.parse(localStorage.getItem('time_config'))?.descricao_local ? JSON.parse(localStorage.getItem('time_config'))?.descricao_local : 'Local'

    useEffect(() => {
        if (tipo === 'auditados') {
            let vencimentos = prompt("Quantidade de Dias a Vencer", "15")
            let verificacao = prompt("Auditados nos Últimos", "7")
            setVencimentos(vencimentos)
            setVerificacao(verificacao)
            GetIndiceDias(vencimentos, verificacao)
        }
        if (tipo === 'todos') {
            let vencimentos = prompt("Quantidade de Dias a Vencer", "15")
            setVencimentos(vencimentos)
            GetIndiceDias(vencimentos)
        }
        if (tipo === 'produtos') {
            let vencimentos = prompt("Quantidade de Dias a Vencer", "15")
            setVencimentos(vencimentos)
            GetIndiceDiasProduto(vencimentos)
            setTab('produtos')
        }
        if (tipo === 'produtos-pendentes-times') {
            let vencimentos = prompt("Quantidade de Dias a Vencer", "15")
            setVencimentos(vencimentos)
            GetIndiceDiasProdutoTodosTimes(vencimentos)
            setTab('produtos')
        }

    }, [])

    function GetIndiceDias(vencimento, verificacao) {

        setLoading(true)

        const url = conexao.endereco + '/coletas/indice/resumo/dias'

        let body = {
            "time": localStorage.getItem('time_id'),
            "vencimento": vencimento,
            "verificacao": verificacao
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setColetas(resposta)
                setLoading(false)
            }
        }
    }

    function GetIndiceDiasProduto(vencimentos, verificacao, departamento) {

        setLoading(true)

        const url = conexao.endereco + '/coletas/indice/resumo/dias/produtos'

        let body = {
            "time": localStorage.getItem('time_id'),
            "vencimento": vencimentos,
            "verificacao": verificacao,
            "departamento": departamento,
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setColetasProdutos(resposta)
                setTab('produtos')
                setLoading(false)
            }
        }
    }
    function GetIndiceDiasProdutoTodosTimes(vencimentos, verificacao, departamento) {

        setLoading(true)

        const url = conexao.endereco + '/coletas/indice/resumo/dias/produtos/todostimes'

        let body = {
            "usuario": localStorage.getItem('email'),
            "vencimento": vencimentos,
            "verificacao": verificacao,
            "departamento": departamento,
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setColetasProdutos(resposta)
                setTab('produtos')
                setLoading(false)
            }
        }
    }

    function GetDetalheColeta(identificacao) {

        setLoading(true)

        const url = conexao.endereco + '/coletas/detalhe'

        let body = {
            "usuario": "",
            "time": localStorage.getItem('time_id'),
            "id": identificacao
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            setLoading(false)
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setColetaSelecionada(resposta)
                setLoading(false)
                setModalDetColeta(true)
                if (resposta.qtde_historico > 0) {
                    GetHistColeta(identificacao)
                } else {
                    setHistoricoColeta([])
                }
            }
        }
    }

    function GetHistColeta(identificacao) {

        setLoading(true)

        const url = conexao.endereco + '/coletas/historico'

        let body = {
            "id": identificacao
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setHistoricoColeta(resposta)
                setLoading(false)
            }
        }
    }



    const paginationComponentOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const colunas = [
        {
            name: 'Departamento',
            selector: row => row.departamento,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Produtos',
            selector: row => row.produtos,
            sortable: true,
            maxWidth: '180px',
        },
        {
            name: 'Quantidades',
            selector: row => Number(row.quantidades),
            sortable: true,
            maxWidth: '180px',
            right: 'true'
        },
        {
            name: 'Valor Total',
            selector: row => Number(row.total),
            format: row => Number(row.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            sortable: true,
            maxWidth: '180px',
            right: 'true'
        },

    ];

    const colunasColetas = [
        {
            name: 'Código',
            selector: row => row.codigo,
            cellExport: row => row.codigo,
            sortable: true,
            maxWidth: '90px',
        },
        {
            name: 'Descrição',
            selector: row => row.descricao,
            cellExport: row => row.descricao,
            sortable: true,
            minWidth: '380px',
            // wrap: true,
            // maxWidth: '380px',
        },
        {
            name: 'Código de Barras',
            selector: row => row.codbarras,
            cellExport: row => row.codbarras,
            sortable: true,
            minWidth: '180px',
        },
        {
            name: 'Departamento',
            selector: row => row.departamento,
            cellExport: row => row.departamento,
            sortable: true,
            maxWidth: '180px',
            wrap: true,
            omit: tipo === 'produtos' ? false : true
        },
        {
            name: 'Time',
            selector: row => row.time_nome,
            cellExport: row => row.time_nome,
            sortable: true,
            maxWidth: '180px',
            omit: tipo === 'produtos-pendentes-times' ? false : true
        },
        {
            name: 'Coleta',
            selector: row => row.col_data,
            cellExport: row => row.col_data,
            format: row => moment(row.col_data).format('DD/MM/YYYY'),
            sortable: true,
            maxWidth: '180px',
            omit: tipo === 'produtos-pendentes-times' ? false : true
        },
        {
            name: 'Usuário Coleta',
            selector: row => row.col_usuario,
            cellExport: row => row.col_usuario,
            sortable: true,
            minWidth: '280px',
            omit: tipo === 'produtos-pendentes-times' ? false : true
        },
        {
            name: 'Data Atualização',
            selector: row => row.data_atualizacao,
            cellExport: row => row.data_atualizacao,
            format: row => row.data_atualizacao ? moment(row.data_atualizacao).format('DD/MM/YYYY') : '',
            sortable: true,
            minWidth: '180px',
            omit: tipo === 'produtos-pendentes-times' ? false : true
        },
        {
            name: 'Usuário Atualização',
            selector: row => row.usuario_atualizacao,
            cellExport: row => row.usuario_atualizacao,
            sortable: true,
            minWidth: '230px',
            omit: tipo === 'produtos-pendentes-times' ? false : true
        },
        // {
        //     name: descricaoLocal,
        //     selector: row => row.local,
        //     cellExport: row => row.local,
        //     sortable: true,
        //     wrap: true,
        //     maxWidth: '180px',
        // },
        {
            name: 'Quantidade',
            selector: row => Number(row.quantidade),
            cellExport: row => Number(row.quantidade),
            sortable: true,
            minWidth: '180px',
            right: 'true'
        },
        {
            name: 'Vencimento',
            selector: row => row.vencimento,
            cellExport: row => row.vencimento,
            sortable: true,
            minWidth: '180px',
            right: 'true'
        },
        {
            name: 'Valor Unitário',
            selector: row => Number(row.valor_unit),
            cellExport: row => Number(row.valor_unit),
            format: row => Number(row.valor_unit).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            sortable: true,
            maxWidth: '180px',
            right: 'true'
        },
        {
            name: 'Valor Total',
            selector: row => Number(row.valor_total),
            cellExport: row => Number(row.valor_total),
            format: row => Number(row.valor_total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            sortable: true,
            maxWidth: '180px',
            right: 'true'
        },
    ];

    const colunasDetalhe = [
        {
            name: 'Campo',
            selector: row => row.Campo,
            maxWidth: '200px',
        },
        {
            name: 'Conteúdo',
            selector: row => row.Conteudo,
        }
    ];

    const colunasHistorico = [
        {
            name: 'Data',
            selector: row => row.data_format,
            cellExport: row => row.data_format,
            maxWidth: '120px'
        },
        {
            name: 'Usuário',
            selector: row => row.hcol_usuario,
            cellExport: row => row.hcol_usuario,
            maxWidth: '200px',
        },
        {
            name: 'Qtde Anterior',
            selector: row => Number(row.hcol_qtde_anterior),
            cellExport: row => Number(row.hcol_qtde_anterior),
            right: 'true'
        },
        {
            name: 'Qtde Atualizada',
            selector: row => Number(row.hcol_qtde),
            cellExport: row => Number(row.hcol_qtde),
            right: 'true'
        }
    ];

    const listaDetalhesColeta = [
        {
            "Campo": "Produto",
            "Conteudo": coletaSelecionada?.prod_descricao
        },
        {
            "Campo": "Código de Barras",
            "Conteudo": coletaSelecionada?.prod_codbarras
        },
        {
            "Campo": "Usuário Coleta",
            "Conteudo": coletaSelecionada?.col_user_integracao ? coletaSelecionada?.col_user_integracao : coletaSelecionada?.col_usuario
        },
        {
            "Campo": "Data da Coleta",
            "Conteudo": coletaSelecionada?.data_format
        },
        {
            "Campo": "Data de Vencimento",
            "Conteudo": coletaSelecionada?.vencimento_format
        },
        {
            "Campo": "Quantidade Original",
            "Conteudo": coletaSelecionada?.qtde_original
        },
        {
            "Campo": "Quantidade Atual",
            "Conteudo": coletaSelecionada?.col_qtde
        },
        {
            "Campo": "Data Última Atualizacao",
            "Conteudo": coletaSelecionada?.ultima_atualizacao
        },
        {
            "Campo": descricaoLote,
            "Conteudo": coletaSelecionada?.col_lote
        },
        {
            "Campo": descricaoLocal,
            "Conteudo": coletaSelecionada?.col_local
        },
        {
            "Campo": "Time",
            "Conteudo": coletaSelecionada?.time
        },
    ]


    const tableDataConfigColetas = {
        columns: colunasColetas,
        data: coletasProdutos,
        filterPlaceholder: 'Filtrar',
        exportHeaders: true,
        print: true,
        export: true
    };


    return (
        <>

            <div className='principal'>
                <Sidebar />
                <div className="principal-content">
                    <TopBar />
                    <div className="conteudo">
                        <Row>
                            <Col>
                                {tab === 'principal' &&
                                    <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                                        {verificacao > 0 &&
                                            <Card.Header className="dashboard-cartao" >Departamentos com Vencimentos dos Próximos {vencimentos} Dias, Auditados nos Últimos {verificacao} Dias</Card.Header>
                                        }
                                        {verificacao == 0 &&
                                            <Card.Header className="dashboard-cartao" >Departamentos com Vencimentos dos Próximos {vencimentos} Dias</Card.Header>
                                        }

                                        <Card.Body>
                                            <DataTable
                                                columns={colunas}
                                                data={coletas}
                                                onRowClicked={(row, index) => {
                                                    GetIndiceDiasProduto(vencimentos, verificacao, row.departamento)
                                                }}
                                                striped={true}
                                                highlightOnHover={true}
                                                responsive={true}
                                                pointerOnHover={true}
                                                noDataComponent={"Nenhum Registro Encontrado =/"}
                                                pagination={true}
                                                paginationComponentOptions={paginationComponentOptions}
                                            />
                                        </Card.Body>
                                    </Card>
                                }
                                {tab === 'produtos' &&
                                    <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                                        <Card.Header className="dashboard-cartao-det-coleta" >
                                            {verificacao > 0 &&
                                                <div style={{ alignItems: 'center', display: 'flex' }}>
                                                    Produtos com Vencimentos dos Próximos {vencimentos} Dias, Auditados nos Últimos {verificacao} Dias
                                                </div>
                                            }
                                            {verificacao == 0 &&
                                                <div style={{ alignItems: 'center', display: 'flex' }}>
                                                    Produtos com Vencimentos dos Próximos {vencimentos} Dias
                                                </div>
                                            }

                                            {tipo !== 'produtos' || tipo !== 'produtos-pendentes-times' &&
                                                <div>
                                                    <Button variant="light" size="sm" onClick={() => {
                                                        setColetasProdutos([])
                                                        setTab('principal')

                                                    }}>Voltar</Button>
                                                </div>
                                            }
                                        </Card.Header>
                                        <Card.Body>
                                            <DataTableExtensions {...tableDataConfigColetas}>
                                                <DataTable
                                                    columns={colunasColetas}
                                                    data={coletasProdutos}
                                                    striped={true}
                                                    highlightOnHover={true}
                                                    pointerOnHover={true}
                                                    onRowClicked={(row, index) => {
                                                        setTabSelecionada('detalhes')
                                                        GetDetalheColeta(row?.col_id)
                                                    }}
                                                    responsive={true}
                                                    noDataComponent={"Nenhum Registro Encontrado =/"}
                                                    pagination={true}
                                                    paginationComponentOptions={paginationComponentOptions}
                                                />
                                            </DataTableExtensions>
                                        </Card.Body>
                                    </Card>
                                }
                            </Col>
                        </Row>
                    </div>
                </div>

            </div>

            <Modal
                show={modalDetColeta}
                onHide={() => { setModalDetColeta(false) }}
                backdrop="static"
                keyboard={false}
                size="sm"
                centered
                dialogClassName="modal-60"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{coletaSelecionada?.prod_descricao}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                            <ButtonGroup size="sm" style={{ marginBottom: 15 }}>
                                <Button variant="light" active={tabSelecionada === 'detalhes' ? true : false} onClick={() => {
                                    setTabSelecionada('detalhes')
                                }}>Dados da Coleta</Button>
                                <Button variant="light" active={tabSelecionada === 'historico' ? true : false} onClick={() => {
                                    setTabSelecionada('historico')
                                }}>Histórico de Atualizações</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img
                                width={'auto'}
                                height={300}
                                src={'https://img.simplustec.com.br/product_images/' + parseInt(coletaSelecionada?.prod_codbarras, 10) + '/1/' + parseInt(coletaSelecionada?.prod_codbarras, 10) + '_1_1_600_72_RGB.png?pid=14136&cmid=3744&action=API&hash=e17c600fb462331d05b82f81b2f19e1e'}
                                loading="lazy"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "https://app.gestaodevalidades.com.br/images/not_found.png";
                                }}
                            />
                        </Col>
                        <Col lg={8} >
                            {tabSelecionada === 'detalhes' &&
                                <DataTable
                                    columns={colunasDetalhe}
                                    data={listaDetalhesColeta}
                                    striped={true}
                                    dense
                                    highlightOnHover={true}
                                    responsive={true}
                                    noDataComponent={"Nenhum Registro Encontrado =/"}
                                />
                            }
                            {tabSelecionada === 'historico' &&
                                <DataTable
                                    columns={colunasHistorico}
                                    data={historicoColeta}
                                    striped={true}
                                    highlightOnHover={true}
                                    pointerOnHover={true}
                                    responsive={true}
                                    noDataComponent={"Nenhum Registro Encontrado =/"}
                                    pagination={true}
                                    paginationComponentOptions={paginationComponentOptions}
                                />
                            }
                        </Col>
                    </Row>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => { setModalDetColeta(false) }}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    )
}

export default AnalyticsAnaliseVencimentos