import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../../components/sidebar";
import TopBar from "../../components/topbar";
import DataTable from "react-data-table-component";
import { GlobalContext } from "../../contexts/global";
import { Col, Row, Card, Form, ButtonGroup, Button, Modal } from 'react-bootstrap'
import Papa from 'papaparse'

function CadastroProdutos() {

    const { conexao, timeSelecionado, loading, setLoading } = useContext(GlobalContext)

    const [produtos, setProdutos] = useState([])
    const [departamentos, setDepartamentos] = useState([])
    const [telaAtualizaProduto, setTelaAtualizaProduto] = useState(false)

    const [produtoAtual, setProdutoAtual] = useState({})

    const [dadosCsv, setDadosCsv] = useState([])
    const [arquivoCsv, setArquivoCsv] = useState(null)

    const [modalUpload, setModalUpload] = useState(false)

    useEffect(() => {
        GetProdutos()
    }, [timeSelecionado])

    function GetProdutos() {
        setLoading(true)

        const url = conexao.endereco + '/produtos/listar'

        let body = {
            "time": timeSelecionado.id,
            "usuario": ""
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setProdutos(resposta)
                setLoading(false)
            }
        }
    }

    function AtualizaProduto() {

        setLoading(true)

        const url = conexao.endereco + '/produtos/atualizar'

        let body = {
            "id": produtoAtual.id,
            "descricao": produtoAtual.descricao,
            "dpto": produtoAtual.departamento

        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            setLoading(false)
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                GetProdutos()

            }
        }
    }

    function GetDepartamentos() {

        setLoading(true)

        const url = conexao.endereco + '/departamentos/listar'

        let body = {
            "time": timeSelecionado.id,
            "usuario": ""
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setDepartamentos(resposta)
                setLoading(false)
                setTelaAtualizaProduto(true)
            }
        }
    }


    const paginationComponentOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const colunas = [
        {
            name: 'Código',
            selector: row => row.prod_codigo,
            sortable: true,
            // minWidth: '120px',
            maxWidth: '120px',
        },
        {
            name: 'Descrição',
            selector: row => row.prod_descricao,
            sortable: true,
            // minWidth: '200px',
        },
        {
            name: 'EAN',
            selector: row => row.prod_codbarras,
            sortable: true,
            // minWidth: '120px',
            maxWidth: '230px',
        },
        {
            name: 'Departamento',
            selector: row => row.prod_departamento,
            sortable: true,
            // minWidth: '180px',
            maxWidth: '320px',
        },
    ];

    return (
        <>

            <div className='principal'>
                <Sidebar />
                <div className="principal-content">
                    <TopBar />
                    <div className="conteudo">
                        <Row>
                            <Col>
                                <ButtonGroup size="sm" style={{ marginBottom: 15 }}>
                                    <Button variant="dark" onClick={() => {
                                        setModalUpload(true)
                                    }}>Importar CSV</Button>
                                </ButtonGroup>
                                <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                                    <Card.Header className="dashboard-cartao">Cadastro de Produtos</Card.Header>
                                    <Card.Body>
                                        <DataTable
                                            columns={colunas}
                                            data={produtos}
                                            onRowClicked={(row, index) => {
                                                setProdutoAtual({
                                                    id: row.prod_id,
                                                    descricao: row.prod_descricao,
                                                    departamento: row.prod_departamento
                                                })
                                                GetDepartamentos()
                                            }}
                                            striped={true}
                                            highlightOnHover={true}
                                            responsive={true}
                                            pointerOnHover={true}
                                            noDataComponent={"Nenhum Registro Encontrado =/"}
                                            pagination={true}
                                            paginationComponentOptions={paginationComponentOptions}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>

                <Modal
                    show={telaAtualizaProduto}
                    onHide={() => { setTelaAtualizaProduto(false) }}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Atualizar Cadastro do Produto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control size="sm" type="text" onChange={(e) => { setProdutoAtual({ ...produtoAtual, descricao: e.target.value }) }} value={produtoAtual?.descricao} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Departamento</Form.Label>
                                    <Form.Select aria-label="Default select example" size="sm" onChange={(e) => { setProdutoAtual({ ...produtoAtual, departamento: e.target.value }) }} value={produtoAtual?.departamento}>
                                        <option>Selecione o Departamento</option>
                                        {departamentos.map((item) => (
                                            <option value={item.dpto_descricao}>{item.dpto_descricao}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="light" onClick={() => { setTelaAtualizaProduto(false) }}>
                            Fechar
                        </Button>
                        <Button variant="dark" onClick={() => {

                            AtualizaProduto()
                            setTelaAtualizaProduto(false)

                        }}>Atualizar</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={modalUpload}
                    onHide={() => { setModalUpload(false) }}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Importar Produtos de Arquivo CSV</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <em>Arquivo deve ter 4 colunas</em><br /><em>[CODPROD, DESCRICAO, CODBARRAS, DEPARTAMENTO]</em>
                        <Row>
                            <Col lg={12} style={{ marginTop: 40 }}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Control size="sm" type="file" onChange={(e) => { setArquivoCsv(e.target.files[0]) }} />
                                </Form.Group>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="light" onClick={() => { setModalUpload(false) }}>
                            Fechar
                        </Button>
                        <Button variant="dark" onClick={() => {
                            Papa.parse(arquivoCsv, {
                                header: true,
                                dynamicTyping: true,
                                complete: (results) => {

                                    console.log(JSON.stringify(results.data))
                                }
                            })
                        }}>Importar</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        </>
    )
}

export default CadastroProdutos