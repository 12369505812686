import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../../components/sidebar";
import TopBar from "../../components/topbar";

import DataTable from "react-data-table-component";
import { MdSettings } from 'react-icons/md'

import { GlobalContext } from "../../contexts/global";

import { Col, Row, Card, Modal, Form, Button } from 'react-bootstrap'

function TelaMembros() {

    const { conexao, timeSelecionado, setLoading } = useContext(GlobalContext)

    const [membros, setMembros] = useState([])

    const [modalCfgMembro, setModalCfgMembro] = useState(false)

    const [membroSelecionado, setMembroSelecionado] = useState({})

    useEffect(() => {
        GetMembros()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeSelecionado])

    function GetMembros() {

        setMembros([])

        setLoading(true)

        const url = conexao.endereco + '/times/membros/listar'

        let body = {
            "time": timeSelecionado.id
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setMembros(resposta)
                setLoading(false)
            }
        }
    }

    // function AtualizarDepartamento(dados) {

    //     setLoading(true)

    //     const url = conexao.endereco + '/departamentos/atualizar'

    //     let body = {
    //         "time": dados.time,
    //         "codigo": dados.codigo,
    //         "dias_alerta": dados.dias_alerta
    //     }

    //     let requisicao = new XMLHttpRequest()

    //     requisicao.timeout = 10000
    //     requisicao.open("PUT", url, true)
    //     requisicao.setRequestHeader("Content-Type", "application/json")
    //     requisicao.setRequestHeader("token", localStorage.getItem('Token'))
    //     requisicao.send(JSON.stringify(body))

    //     requisicao.ontimeout = function () {
    //         console.log('Deu TimeOut')
    //         setLoading(false)
    //     }

    //     requisicao.onerror = function () {
    //         console.log('Deu Erro: ' + this.responseText)
    //         setLoading(false)
    //     }

    //     requisicao.onload = function () {

    //         if (requisicao.status !== 200) {
    //             let resposta = JSON.parse(this.response)
    //             alert(resposta.message)
    //             setLoading(false)
    //         } else {
    //             GetDepartamentos()
    //         }
    //     }
    // }

    const paginationComponentOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const colunas = [
        {
            name: 'Usuário',
            selector: row => row.usuario,
            sortable: true,
        },
        {
            name: 'Tipo',
            selector: row => row.tipo,
            sortable: true,
        },
        // {
        //     name: 'Configurar',
        //     button: true,
        //     center: true,
        //     cell: row => (
        //         // eslint-disable-next-line
        //         < a onClick={() => {
        //             setMembroSelecionado({
        //                 ...membroSelecionado,
        //                 nome: row.dpto_descricao,
        //                 codigo: row.dpto_codigo,
        //                 time: timeSelecionado.id,
        //                 usuario: localStorage.getItem('email'),
        //                 dias_alerta: row.dpto_dias_cor
        //             })
        //             setModalCfgMembro(true)
        //         }} className="icone-datagrid">
        //             <MdSettings size="24" title="Configurar Departamento" />
        //         </a>
        //     ),
        // },
    ];

    return (
        <>

            <div className='principal'>
                <Sidebar />
                <div className="principal-content">
                    <TopBar />
                    <div className="conteudo">
                        <Row>
                            <Col>
                                <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                                    <Card.Header className="dashboard-cartao">Cadastro de Membros</Card.Header>
                                    <Card.Body>
                                        <DataTable
                                            columns={colunas}
                                            data={membros}
                                            // onRowClicked={(row, index) => {
                                            //     GeraLogServidor(usuario.email, 'DASH', 'VENCIMENTOS', 'Clicou no Departamento: ' + row.departamento + ', Vencimento: ' + row.vencimento)
                                            //     CarregaDetalheColetas(row.departamento, row.vencimento)
                                            // }}
                                            striped={true}
                                            highlightOnHover={true}
                                            responsive={true}
                                            // pointerOnHover={true}
                                            noDataComponent={"Nenhum Registro Encontrado =/"}
                                            pagination={true}
                                            paginationComponentOptions={paginationComponentOptions}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>

            </div>


            <Modal
                show={modalCfgMembro}
                onHide={() => { setModalCfgMembro(false) }}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{membroSelecionado?.nome}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Número de Dias de Alerta</Form.Label>
                                <Form.Control size="sm" type="number" onChange={(e) => { setMembroSelecionado({ ...membroSelecionado, dias_alerta: e.target.value }) }} value={membroSelecionado.dias_alerta} />
                                <Form.Text className="text-muted">
                                    Departamento Será Exibido em Vermelho no Aplicativo
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => { setModalCfgMembro(false) }}>
                        Fechar
                    </Button>
                    <Button variant="dark" onClick={() => {


                        setModalCfgMembro(false)
                        // AtualizarDepartamento(membroSelecionado)

                    }}>Aplicar</Button>
                </Modal.Footer>
            </Modal>


        </>
    )
}

export default TelaMembros