import React, { useEffect, useContext } from "react";
import Sidebar from "../../components/sidebar";
import TopBar from "../../components/topbar";

import { Col, Row, Card, Form, Button } from 'react-bootstrap'

import { GlobalContext } from '../../contexts/global'
import { useState } from "react";

import Switch from "react-switch";

function Configuracoes() {

    const [config, setConfig] = useState({})
    const [notificacaoDiaria, setNotificacaoDiaria] = useState(false)

    const { conexao, setLoading, timeSelecionado } = useContext(GlobalContext)

    useEffect(() => {
        GetConfigs()
    }, [timeSelecionado.id])

    useEffect(() => {
        GetConfigs()
    }, [])


    function GetConfigs() {

        setLoading(true)

        const url = conexao.endereco + '/times/configuracoes/listar'

        setConfig({
            horario_maximo_integracao: '',
            email_notificar_integracao: '',
            descricao_lote: '',
            descricao_local: ''
        })

        setNotificacaoDiaria(false)

        let body = {
            "time": localStorage.getItem('time_id')
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            setLoading(false)
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setConfig(resposta)

                if (resposta.notificacaoDiaria) {
                    setNotificacaoDiaria(true)
                }
            }


        }
    }

    function AtualizarConfigs() {

        setLoading(true)

        const url = conexao.endereco + '/times/configuracoes/atualizar'

        let body = {
            "time": localStorage.getItem('time_id'),
            "dados": config
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("PUT", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            setLoading(false)
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                // setConfig(resposta)

            }
        }
    }

    return (
        <>

            <div className='principal'>
                <Sidebar />
                <div className="principal-content">
                    <TopBar />
                    <div className="conteudo">
                        <Row>
                            <Col style={{ marginBottom: 20 }}>
                                <span>Configurações do Time <strong>{localStorage.getItem('time_nome')}</strong></span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                                    <Card.Header className="dashboard-cartao">Integração com ERP</Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col lg={3}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Label>Horário Máximo Integração</Form.Label>
                                                    <Form.Control size="sm" type="time" onChange={(e) => { setConfig({ ...config, horario_maximo_integracao: e.target.value }) }} value={config.horario_maximo_integracao} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Label>E-mail Notificação</Form.Label>
                                                    <Form.Control size="sm" type="email" onChange={(e) => { setConfig({ ...config, email_notificar_integracao: e.target.value }) }} value={config.email_notificar_integracao} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Label>Descrição Campo Lote</Form.Label>
                                                    <Form.Control size="sm" type="text" onChange={(e) => { setConfig({ ...config, descricao_lote: e.target.value }) }} value={config.descricao_lote} />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Label>Descrição Campo Local</Form.Label>
                                                    <Form.Control size="sm" type="text" onChange={(e) => { setConfig({ ...config, descricao_local: e.target.value }) }} value={config.descricao_local} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card style={{ minWidth: '18rem', marginBottom: 30 }}>
                                    <Card.Header className="dashboard-cartao">Notificações no Aplicativo</Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col lg={12}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ marginRight: 15 }}>Enviar Notificação Diária</span>
                                                    <Switch
                                                        checked={notificacaoDiaria}
                                                        onChange={(e) => {
                                                            setConfig({ ...config, notificacaoDiaria: e })
                                                            setNotificacaoDiaria(!notificacaoDiaria)
                                                        }}
                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                        className="react-switch"
                                                        id="disabled-switch"
                                                    />
                                                    <span style={{ marginLeft: 15, color: 'gray' }}>Será Enviada a Todos os Membros do Time</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', paddingBottom: 15 }}>
                            <Button
                                variant="dark"
                                style={{ marginRight: 15, width: 200 }}
                                onClick={() => {
                                    AtualizarConfigs()
                                    // alert(JSON.stringify(config))
                                }}
                            >Salvar Alterações
                            </Button>
                        </Row>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Configuracoes