import React from "react";
import Sidebar from "../../components/sidebar";
import TopBar from "../../components/topbar";
import { useNavigate } from 'react-router-dom'

import { Col, Row, Card } from 'react-bootstrap'
import { CartaoBotao, CartaoNumeros, CartaoProximosVencimentos } from '../../components/cards'

function Analytics() {

    const Navigate = useNavigate();

    return (
        <>

            <div className='principal'>
                <Sidebar />
                <div className="principal-content">
                    <TopBar />
                    <div className="conteudo">
                        <Row>
                            <Col lg={4}>
                                <CartaoBotao
                                    titulo="Análise de Vencimentos [Departamentos]"
                                    conteudo={<p>Lista os vencimentos de um período especifico, <strong>apenas auditados</strong></p>}
                                    textobotao="Acessar"
                                    link={'/analytics/vencimentos/analise/auditados'}
                                />
                            </Col>
                            <Col lg={4}>
                                <CartaoBotao
                                    titulo="Análise de Vencimentos [Departamentos]"
                                    conteudo={<p>Lista os vencimentos de um período especifico, <strong>todos os produtos</strong></p>}
                                    textobotao="Acessar"
                                    link={'/analytics/vencimentos/analise/todos'}
                                />
                            </Col>
                            <Col lg={4}>
                                <CartaoBotao
                                    titulo="Análise de Vencimentos [Produtos]"
                                    conteudo={<p>Lista os vencimentos de um período especifico, <strong>todos os produtos</strong></p>}
                                    textobotao="Acessar"
                                    link={'/analytics/vencimentos/analise/produtos'}
                                />
                            </Col>
                            <Col lg={4}>
                                <CartaoBotao
                                    titulo="Análise de Vencimentos Pendentes [Produtos]"
                                    conteudo={<p>Lista os vencimentos pendentes de um período especifico, <strong>todos os produtos e times</strong></p>}
                                    textobotao="Acessar"
                                    link={'/analytics/vencimentos/analise/produtos-pendentes-times'}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Analytics