import React, { useEffect, useContext, useState } from "react";

import Sidebar from "../../components/sidebar";
import TopBar from "../../components/topbar";
import { GlobalContext } from "../../contexts/global";

import { Chart } from "react-google-charts";
import DataTable from "react-data-table-component";

import { Col, Row, Card, Modal, Form, Button } from 'react-bootstrap'

import _ from "lodash";

import { useNavigate } from 'react-router-dom'

import { Cartao, CartaoNumeros, CartaoProximosVencimentos } from '../../components/cards'

function Dashboard() {

    // const [loading, setLoading] = useState(false)

    const { conexao, timeSelecionado, periodo, modalPeriodo, setModalPeriodo, setPeriodo, loading, setLoading } = useContext(GlobalContext)

    const [indicadores, setIndicadores] = useState([])
    const [ranking, setRanking] = useState([])
    const [departamentos, setDepartamentos] = useState([])

    const [dataInicial, setDataInicial] = useState('')
    const [dataFinal, setDataFinal] = useState('')

    const Navigate = useNavigate();

    useEffect(() => {

        var date = new Date();
        var primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
        var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        GetDashboardStats(primeiroDia, ultimoDia)
        //eslint-disable-next-line
    }, [timeSelecionado.id])


    useEffect(() => {

        if (periodo?.id === 'mes_atual') {
            var date = new Date();
            var primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
            var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            GetDashboardStats(primeiroDia, ultimoDia)

        } else

            if (periodo?.id === 'mes_anterior') {
                var date = new Date();
                var primeiroDia = new Date(date.getFullYear(), date.getMonth() - 1, 1);
                var ultimoDia = new Date(date.getFullYear(), date.getMonth() - 1 + 1, 0);

                GetDashboardStats(primeiroDia, ultimoDia)
            }


    }, [periodo])


    function GetDashboardStats(datainicial, datafinal) {

        setLoading(true)

        const url = conexao.endereco + '/dashboard/indicadores'

        let body = {
            "time": timeSelecionado.id,
            "p1": 60,
            "p2": 90,
            "p3": 120,
            "datainicial": datainicial.toISOString().split('T')[0],
            "datafinal": datafinal.toISOString().split('T')[0],
            "ordem": 4
        }

        let requisicao = new XMLHttpRequest()

        requisicao.timeout = 10000
        requisicao.open("POST", url, true)
        requisicao.setRequestHeader("Content-Type", "application/json")
        requisicao.setRequestHeader("token", localStorage.getItem('Token'))
        requisicao.send(JSON.stringify(body))

        requisicao.ontimeout = function () {
            console.log('Deu TimeOut')
            setLoading(false)
        }

        requisicao.onerror = function () {
            console.log('Deu Erro: ' + this.responseText)
            setLoading(false)
        }

        requisicao.onload = function () {
            if (requisicao.status === 200) {
                let resposta = JSON.parse(this.response)
                setIndicadores(resposta)
                setRanking(resposta.ranking)

                // lista.unshift("Departamento", "Total", "Perdas", "Recuperados")

                const listaTratada = _.map(resposta.departamentos, (campo) => [
                    campo.dpto_descricao, Number(campo.total), Number(campo.recuperados), Number(campo.perdidos)
                ])

                setDepartamentos(listaTratada)

                setLoading(false)
            } else {
                setLoading(false)
            }
        }

    }

    const graficoDepartamentos = [

        ["Departamento", "Total", "Perdas", "Recuperados"], ...departamentos
    ]

    const proximosVencimentos = [
        ["Período", "Volume", "Valor"],
        [indicadores.periodo1?.periodo + " Dias", indicadores.periodo1?.quantidade, indicadores.periodo1?.valor_number],
        [indicadores.periodo2?.periodo + " Dias", indicadores.periodo2?.quantidade, indicadores.periodo2?.valor_number],
        [indicadores.periodo3?.periodo + " Dias", indicadores.periodo3?.quantidade, indicadores.periodo3?.valor_number],
        ["+ " + indicadores.periodo4?.periodo + " Dias", indicadores.periodo4?.quantidade, indicadores.periodo4?.valor_number],

    ]

    const options_chart_departamentos = {
        animation: {
            startup: true,
            easing: "linear",
            duration: 500,
        },
        chartArea: { width: "50%" },
        hAxis: {
            title: "Evolução",
            minValue: 0,
        },
        vAxis: {
            title: "Departamento",
        },
    }
    const options_chart_times = {
        animation: {
            startup: true,
            easing: "linear",
            duration: 500,
        },

        chartArea: { width: "50%" },
        hAxis: {
            title: "Evolução",
            minValue: 0,
        },
        vAxis: {
            title: "Time",
        },

    }
    const options_chart_proximos_vencimentos = {
        animation: {
            startup: true,
            easing: "linear",
            duration: 500,
        },
        chartArea: { width: "70%" },
        bars: "horizontal",
        colors: ['green', 'rgb(14, 172, 235)',]
    }

    const colunasRanking = [
        {
            name: 'Fornecedor',
            selector: row => row.fornecedor,
            sortable: true,
            minWidth: '220px',

        },
        {
            name: 'Coletas',
            selector: row => Number(row.coletas),
            sortable: true,
            right: true,
            maxWidth: '160px',
        },
        {
            name: 'Volume',
            selector: row => Number(row.volume),
            sortable: true,
            right: true,
            maxWidth: '160px',
        },
        {
            name: 'Valor (R$)',
            selector: row => Number(row.valor),
            format: row => Number(row.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            sortable: true,
            right: true,
            maxWidth: '160px',
        }
    ];

    const paginationComponentOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };



    return (
        <>
            <div className='principal'>
                <Sidebar />
                <div className="principal-content">
                    <TopBar />
                    {!modalPeriodo &&
                        <div className="conteudo">

                            <Row>
                                <Col lg={4}>
                                    <CartaoNumeros
                                        titulo="Produtos Pendentes de Vencimento"
                                        numero={indicadores.pendentes?.quantidade}
                                        valor={indicadores.pendentes?.valor}
                                        participacao={parseFloat((indicadores.pendentes?.valor_number / indicadores.identificados?.valor_number) * 100).toFixed(2) + "%"}
                                        cor="rgb(14, 172, 235)"
                                    />
                                </Col>
                                <Col lg={8}>
                                    <CartaoProximosVencimentos
                                        titulo="Vencimentos nos Próximos Dias"
                                        valor1="R$ 1043.30"
                                        participacao1="12.23%"
                                        valor2="R$ 543.30"
                                        participacao2="7.21%"
                                        valor3="R$ 3012.94"
                                        participacao3="23.93%"
                                        valor4="R$ 8301.21"
                                        participacao4="49.23%"
                                        cor1="brown"
                                        cor2="orange"
                                        cor3="green"
                                        cor4="rgb(14, 172, 235)"
                                        data={proximosVencimentos}
                                        options={options_chart_proximos_vencimentos}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CartaoNumeros
                                        titulo="Total de Produtos Coletados"
                                        numero={indicadores.identificados?.quantidade}
                                        valor={indicadores.identificados?.valor}
                                        participacao="100%"
                                        cor="rgb(14, 172, 235)"
                                    />
                                </Col>
                                <Col>
                                    <CartaoNumeros
                                        titulo="Produtos Recuperados"
                                        numero={indicadores.recuperados?.quantidade}
                                        valor={indicadores.recuperados?.valor}
                                        participacao={parseFloat((indicadores.recuperados?.valor_number / indicadores.identificados?.valor_number) * 100).toFixed(2) + "%"}
                                        cor="green"
                                    />
                                </Col>
                                <Col>
                                    <CartaoNumeros
                                        titulo="Produtos Perdidos"
                                        numero={indicadores.perdidos?.quantidade}
                                        valor={indicadores.perdidos?.valor}
                                        participacao={parseFloat((indicadores.perdidos?.valor_number / indicadores.identificados?.valor_number) * 100).toFixed(2) + "%"}
                                        cor="brown"
                                        link="/analytics/produtos/perdidos"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Cartao
                                        titulo="Resultado por Departamento"
                                        conteudo={
                                            <Chart
                                                chartType="BarChart"
                                                width="100%"
                                                height="550px"
                                                data={graficoDepartamentos}
                                                options={options_chart_departamentos}
                                            />
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Cartao
                                        titulo="Ranking Fornecedores com Maior Índice de Perdas"
                                        conteudo={
                                            <DataTable
                                                columns={colunasRanking}
                                                data={ranking}
                                                // onRowClicked={(row, index) => {
                                                //     GeraLogServidor(usuario.email, 'DASH', 'VENCIMENTOS', 'Clicou no Departamento: ' + row.departamento + ', Vencimento: ' + row.vencimento)
                                                //     CarregaDetalheColetas(row.departamento, row.vencimento)
                                                // }}
                                                striped={true}
                                                highlightOnHover={true}
                                                responsive={true}
                                                // pointerOnHover={true}
                                                noDataComponent={"Nenhum Registro Encontrado =/"}
                                                pagination={true}
                                                paginationComponentOptions={paginationComponentOptions}
                                            />
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Cartao
                                        titulo="Últimas Ações Realizadas"
                                        conteudo={
                                            <h3>Conteudo em Desenvolvimento</h3>
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>
                    }
                </div>
            </div>

            <Modal
                show={modalPeriodo}
                onHide={() => { setModalPeriodo(false) }}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Período</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Data Inicial</Form.Label>
                                <Form.Control size="sm" type="date" onChange={(e) => { setDataInicial(e.target.value) }} value={dataInicial} />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Data Final</Form.Label>
                                <Form.Control size="sm" type="date" onChange={(e) => { setDataFinal(e.target.value) }} value={dataFinal} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => { setModalPeriodo(false) }}>
                        Fechar
                    </Button>
                    <Button variant="dark" onClick={() => {

                        var dtI = new Date(dataInicial);
                        var dtF = new Date(dataFinal);

                        const data_inicio_us = dtI.toISOString().split('T')[0]
                        const data_final_us = dtF.toISOString().split('T')[0]

                        const data_inicio_format = data_inicio_us.substring(8, 10) + '/' + data_inicio_us.substring(5, 7) + '/' + data_inicio_us.substring(0, 4)
                        const data_final_format = data_final_us.substring(8, 10) + '/' + data_final_us.substring(5, 7) + '/' + data_final_us.substring(0, 4)


                        GetDashboardStats(dtI, dtF)
                        setPeriodo({ ...periodo, nome: data_inicio_format + ' a ' + data_final_format, id: 'custom' })
                        setModalPeriodo(false)
                        // alert(primeiroDia.toLocaleDateString("pt-BR"))
                        // alert(ultimoDia.toLocaleDateString("pt-BR"))
                    }}>Aplicar</Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

export default Dashboard