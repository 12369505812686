import logo2 from './../assets/barcode.gif'
import React, { useState } from "react";
import { MdDashboard, MdList, MdAddChart, MdOutlineIntegrationInstructions, MdSettings, MdOutlineHelp, MdAddAlert, MdPeople } from 'react-icons/md'
import { BsCaretDownFill, BsCaretUpFill, BsDot } from 'react-icons/bs'
import { BiBarcodeReader } from 'react-icons/bi'

import { useNavigate } from 'react-router-dom'

function Sidebar() {

    const Navigate = useNavigate();

    const [menuCadastros, setMenuCadastros] = useState(false)

    return (

        <div className='app'>
            <div className='sidebar'>
                <div className='sidebar-topo'>
                    <img
                        className='sidebar-logo'
                        src={logo2}
                        width="50%"
                        alt='Logo Sistema'
                    />
                </div>
                <div className='sidebar-content'>
                    <ul>
                        <li
                            onClick={() => { Navigate('/dashboard') }}>
                            <div className='menu-icone'>
                                <MdDashboard size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Dashboard
                            </div>
                        </li>
                        <li
                            onClick={() => { setMenuCadastros(!menuCadastros) }}>
                            <div className='menu-icone'>
                                <MdList size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Cadastros
                                {!menuCadastros ? <BsCaretDownFill style={{ marginLeft: 15 }} /> : <BsCaretUpFill style={{ marginLeft: 15 }} />}
                            </div>
                        </li>
                        {menuCadastros &&
                            <ul className='submenu'>
                                <li onClick={() => { Navigate('/cadastro-produtos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Produtos
                                    </div>
                                </li>
                                <li onClick={() => { Navigate('/cadastro-departamentos') }}>
                                    <div className='submenu-icone'>
                                        <BsDot size={24} color="white" style={{ marginRight: 5 }} />
                                    </div>
                                    <div className='submenu-texto'>
                                        Departamentos
                                    </div>
                                </li>
                            </ul>
                        }
                        <li
                            onClick={() => { Navigate('/coletas') }}>
                            <div className='menu-icone'>
                                <BiBarcodeReader size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Coletas
                            </div>
                        </li>
                        <li
                            onClick={() => { Navigate('/analytics') }}>
                            <div className='menu-icone'>
                                <MdAddChart size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Analytics
                            </div>
                        </li>
                        <li
                            onClick={() => { Navigate('/integracoes') }}>
                            <div className='menu-icone'>
                                <MdOutlineIntegrationInstructions size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Integrações
                            </div>
                        </li>
                        <li
                            onClick={() => { Navigate('/alertas') }}>
                            <div className='menu-icone'>
                                <MdAddAlert size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Alertas
                            </div>
                        </li>
                        <li
                            onClick={() => { Navigate('/membros') }}>
                            <div className='menu-icone'>
                                <MdPeople size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Membros
                            </div>
                        </li>
                        <li
                            onClick={() => { Navigate('/configuracoes') }}>
                            <div className='menu-icone'>
                                <MdSettings size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Configurações
                            </div>
                        </li>
                        <li
                            onClick={() => { Navigate('/ajuda') }}>
                            <div className='menu-icone'>
                                <MdOutlineHelp size={24} color="white" style={{ marginRight: 15 }} />
                            </div>
                            <div className='menu-texto'>
                                Obter Ajuda
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    )
}

export default Sidebar